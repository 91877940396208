import config from '../../config.json'

// export const BASE_URL = (config.DEMO)? "https://aointakeuatapi.azurewebsites.net/": "https://aointakeapi.azurewebsites.net/";
// export const AoPartnerBaseUrlMobile = (config.DEMO)?"https://aoeventcountermobileuat.azurewebsites.net/": "https://aoeventcountermobile.azurewebsites.net/";
// export const AoPartnerBaseUrl = (config.DEMO)?'https://aoadminuat.azurewebsites.net/': 'https://aoadmindev.azurewebsites.net/';
// export const COUNTERBASEURL=(config.DEMO)?"https://aoeventcounteruat.azurewebsites.net/Congratulations": "https://aoeventcounter.azurewebsites.net/Congratulations";
// export const COUNTERBASEURL1=(config.DEMO)?"https://aoeventcounteruat.azurewebsites.net/AfterPaymentScreen": "https://aoeventcounter.azurewebsites.net/AfterPaymentScreen";
// export const COUNTERBASEURL2=(config.DEMO)?"https://aoeventcounteruat.azurewebsites.net/confirmBooking": "https://aoeventcounter.azurewebsites.net/confirmBooking";
// export const AOLOGINBASEURL =  (config.DEMO)?"https://aologinuat.azurewebsites.net/": "https://aologin.azurewebsites.net/";
// export const PAYMENTURL =  (config.DEMO)?"https://aopaymentdev.azurewebsites.net": "https://aopayment.azurewebsites.net";

export const BASE_URL = (config.DEMO)? "https://aointakeuatapi.agingoptions.com/": "https://aointakeapi.azurewebsites.net/";
export const LeadStackerBASE_URL = "https://rest.gohighlevel.com"
export const AoPartnerBaseUrlMobile = (config.DEMO)?"https://aoeventcountermobileuat.agingoptions.com/": "https://event.mcounter.agingoptions.com/";
export const AoPartnerBaseUrl = (config.DEMO)?'https://Aoadminuat.agingoptions.com/': 'https://AOAdmin.agingoptions.com/';
export const COUNTERBASEURL=(config.DEMO)?"https://aoeventcounteruat.agingoptions.com/Congratulations": "https://Event.counter.agingoptions.com";
// export const COUNTERBASEURL1=(config.DEMO)?"https://aoeventcounteruat.agingoptions.com/AfterPaymentScreen": "https://Event.counter.agingoptions.com/AfterPaymentScreen";
export const COUNTERBASEURL1=(config.DEMO)?"https://aoeventcounteruat.agingoptions.com/confirmBooking": "https://Event.counter.agingoptions.com/confirmBooking";
export const COUNTERBASEURL2=(config.DEMO)?"https://aoeventcounteruat.agingoptions.com/confirmBooking": "https://Event.counter.agingoptions.com/confirmBooking";
export const AOLOGINBASEURL =  (config.DEMO)?"https://aologinuat.agingoptions.com/": "https://Unilogin.agingoptions.com/";
export const PAYMENTURL =  (config.DEMO)?"https://aopaymentdev.agingoptions.com": "https://aopayment.azurewebsites.net";
 



let AoUrl ={
    getUserDetailsByUserEmailId:'/api/Account/GetUserDetailByEmailId',
    getIpAddressUrlPath: 'https://geolocation-db.com/json/',
    getSubtenantDetailPath: 'api/Subtenant/get-subtenant-details',
    getSeminarUserListPath: 'api/SeminarUserList/getSeminarUserList',
    getPlannerBookingDetailsPath: 'api/PlannerBookings/getPlannerBookings',
    upsertSeminarAttendeePath: 'api/SeminarAttendee/upsertSeminarAttendee',
    // registerWalkInUserPath: 'api/2/User/RegisterWalkInUser',//
    registerWalkInUserPath: 'api/2/User/RegisterWalkInUser',//
    getCountryCodeListPath: 'api/Country/GetRegistrationEnableCountry',
    updateSeminarAttendeeInfoPath: 'api/SeminarAttendee/UpdateSeminarAttendeeStatus',
    getReferenceModePath: 'api/RefereneMode/GetReferenceMode',
    getPlannerCalenderPath: 'api/PlannerCalendar/getPlannerCalendarDetails',//cng
    upsertPlannerBookingPath: 'api/PlannerBookings/upsertPlannerBookings',
    getLoggedInUserPath: "/api/User/GetLoggedInUser/",
    getAthenticatePath: '/api/User/AuthenticateLoggedInUser/',//
    getSeminarPublisherPath: 'api/SeminarPublisher/getSeminar',
    getPlannerStatusUpdatePath :"/api/PlannerBookings/updatePlannerBookingStatus", //current
    // getConfirmationListPath: "/api/PlannerCalendar/getBookedPlannerSlotListWithAttendee/",//////////////
    getConfirmationListPath: "/api/PlannerBookings/GetPlannerBookingList",//////////////
    postBulkRegistration: '/api/User/BulkUserRegister',
    postAddUserOrder:'/api/UserOrder/AddUserOrder',// 
    // postSendPlannerLink: '/api/SeminarAttendee/SendPlannerLink',//
    SendLinkToAttendee: '/api/SeminarAttendee/SendLinkToAttendee/',//
    postregisteraddress:'/api/Address/add-address-by-userid',
    getregaddressbyuserid:'/api/Address/get-address-by-userid/',
    // registerWalkInUserPath: 'api/User/RegisterWalkInUser',
    // registerWalkInUserForInvestPath: 'api/DinnerWithRajiv/RegisterSeminarUser',
    getSeminarPublishePath: 'api/SeminarPublisher/getSeminar',
    getContactDetails: "/api/Contact/get-contact-by-userid/",
    putAPIForMember: "/api/Member/update-member-by-id",
    putAPIForContact: "/api/Contact/update-contact-by-userid/",
    // upsertSeminarAttendeeAPI: "/api/SeminarAttendee/upsertSeminarAttendee",
    upsertSeminarAttendeeAPI: "api/2/SeminarAttendee/updateSeminarAttendeeV2",
    putAPIForAddress: "/api/Address/update-address",
    putForSheetAlot : "/api/SeminarSeatAllottment/SeatStatus/",
    postCashPayment : "/api/UserOrder/ConfirmUserOrder",
    GetUserRole : "/api/UserRole/GetUserRoleByUserId/",
    GetEmailTemplate : "/api/EmailTemplates/GetEmailTemplates?TemplateId=",
    PostEmailCom : "/api/EmailComm/SendEmailComm",
    userActivation : "/api/UserActivation/GetUserActivationLink",
    getImageBase64: 'api/FileUpload/documents/',
    getPlannerPlans :"api/Planning",

    // ...............................................................................
    getCommMediumPath: '/api/CommMedium/GetCommMedium',
    getTextTemplate: "/api/TextTemplates/GetTextTemplates",
    postSendTextPath: '/api/TextComm/SendText',
    updateUserEmail : '/api/Account/UpdateUserName',
    userActivate11 : '/api/SeminarAttendee/UpdateSeminarAttendeeActiveStatus',
    postClassRoomBooking: 'api/ClassroomBookings/Register',
    putPaymentBookingStatus :  'api/ClassroomBookings/SetPaymentStatus',
    putClassRoomBooking : 'api/ClassroomBookings',
    getClassRoomBooking : 'api/ClassroomBookings',
    getPlannerClassRoomLink : 'api/SeminarAttendeeAccessLink/GetPlannerClassroomLink',
    getSelfcheckingLink : '/api/SeminarAttendeeAccessLink/GetLink',
    getSubtenantRateCardPathSeminar : 'api/ApplicableSubtentantSubscriptions/GetAppSubtenantSubscriptionDetails',
    adduserRoles : "/api/UserRole/AddRoleAsync",
    getLeadstackerContacts : "v1/contacts/lookup",
    postLeadstackerContacts : "v1/contacts/",
    postLogUserout: BASE_URL + 'api/User/LogoutUser/',



    // ...............................................................................

    getIPv4: "/api/CEAuthorization/get-ip",

}

export default AoUrl;


